import {
  Button,
  Dialog,
  DialogTitle,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dotArtProfile from "./dot_art_profile_pic.svg";
import "./App.css";
import "@fontsource/ubuntu";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/900.css";
import GitHubIcon from "@mui/icons-material/GitHub";
import i18n_hub from "./i18n.json";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import CodeIcon from "@mui/icons-material/Code";
import EmailIcon from "@mui/icons-material/Email";
import MenuIcon from "@mui/icons-material/Menu";
import InboxIcon from "@mui/icons-material/Inbox";
import MailIcon from "@mui/icons-material/Mail";

const languages = [
  // 'Arabic',
  "Chinese", // 简化字
  "English",
  "French", // Français
  "German", // Deutsch
  "Russian", // Русский язык
  "Spanish", // Español
];

const languages_nativeSpelling_map = {
  // Arabic: { nativeSpelling: 'ar' },
  Chinese: { nativeSpelling: "简化字" },
  English: { nativeSpelling: "English" },
  French: { nativeSpelling: "Français" },
  German: { nativeSpelling: "Deutsch" },
  Russian: { nativeSpelling: "Русский язык" },
  Spanish: { nativeSpelling: "Español" },
};

function translator(langKey, stringKey) {
  return i18n_hub[langKey][stringKey]
    ? i18n_hub[langKey][stringKey]
    : i18n_hub["en"][stringKey];
}

function App() {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState("English");
  // \/ could be replaced
  const [activeLanguageKey, setActiveLanguageKey] = useState("en");

  const handleLanguageMenuOnClick = (event) => {
    setMenuIsOpen(!menuIsOpen);

    if (event.target.getAttribute("data-language")) {
      const lang = event.target.getAttribute("data-language");
      const langKey = lang.slice(0, 2).toLowerCase();

      setActiveLanguage(lang);

      document.title = translator(langKey, "localeKeyTest");

      // \/ could be replaced
      setActiveLanguageKey(langKey);
    }
  };

  const [contactDialogIsOpen, setContactDialogIsOpen] = useState(false);

  const handleContactDialogOnClick = () => {
    setContactDialogIsOpen(!contactDialogIsOpen);
  };

  const [terminalIsOpen, setTerminalIsOpen] = useState(false);

  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  return (
    <>
      {terminalIsOpen && (
        <div id="terminalWrapper">
          <div id="terminalTopContainer">
            <div id="terminalTopBar">
              <div
                className="circle-button"
                onClick={() => setTerminalIsOpen(!terminalIsOpen)}
              ></div>
              <div
                className="circle-button"
                onClick={() => setTerminalIsOpen(!terminalIsOpen)}
              ></div>
              <div
                className="circle-button"
                onClick={() => setTerminalIsOpen(!terminalIsOpen)}
              ></div>
            </div>
            <div id="terminalBody">
              <div className="typewriter">
                <h1>foo bar.</h1>
              </div>
            </div>
          </div>
        </div>
      )}
      <Drawer
        anchor={"left"}
        open={drawerIsOpen}
        onClose={() => setDrawerIsOpen(!drawerIsOpen)}
      >
        <List>
          {["About", "Starred", "Send email", "Drafts"].map((text, index) => (
            <ListItem
              key={text + index}
              disablePadding
              onClick={() => setDrawerIsOpen(!drawerIsOpen)}
            >
              <ListItemButton>
                <ListItemIcon>
                  {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <AppBar id="topNavWrapper" position="static">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={() => setDrawerIsOpen(!drawerIsOpen)}
          >
            <MenuIcon className="nav-icon" />
          </IconButton>
          {/* <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{ display: { sm: "block" } }}
          >
            <span id="appBarMenuTitle">vyum</span>
          </Typography> */}
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: { md: "flex" } }}>
            <IconButton
              id="appBarMenuLanguagesButton"
              onClick={(event) => handleLanguageMenuOnClick(event)}
            >
              <span className="nav-label">
                {languages_nativeSpelling_map[activeLanguage].nativeSpelling}
              </span>
              {menuIsOpen ? (
                <KeyboardArrowUpIcon className="nav-icon" />
              ) : (
                <KeyboardArrowDownIcon className="nav-icon" />
              )}
            </IconButton>
            <Menu
              id="appBarMenuLanguages"
              anchorEl={() =>
                document.querySelector("#appBarMenuLanguagesButton")
              }
              open={menuIsOpen}
              onClick={(event) => handleLanguageMenuOnClick(event)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              {languages
                .filter((language) => language !== activeLanguage)
                .sort()
                .map((x, i) => (
                  <MenuItem
                    onClick={() => setMenuIsOpen(!menuIsOpen)}
                    key={i}
                    data-language={x}
                  >
                    {languages_nativeSpelling_map[x].nativeSpelling}
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <div id="appCenterContentWrapper">
        <div className="app-center-content-item">
          <img
            src={dotArtProfile}
            id="dotArtProfile"
            alt="Joshua Mummert Stern"
          />
        </div>
      </div>
      <BottomNavigation
        id="bottomNavWrapper"
        showLabels
        // value={value}
        // onChange={(event, newValue) => {
        //   setValue(newValue);
        // }}
      >
        <BottomNavigationAction
          label={<span className="nav-label">CodePen</span>}
          icon={<CodeIcon className="nav-icon" />}
          onClick={() =>
            window.open("https://codepen.io/speaud", "_blank", "noreferrer")
          }
        />
        <BottomNavigationAction
          className={"wiggle-animation"}
          // className={`${!contactDialogIsOpen && "wiggle-animation"}`}
          label={<span className="nav-label">Reach Out!</span>}
          // other label options - get in touch,
          icon={<EmailIcon className="nav-icon" />}
          onClick={handleContactDialogOnClick}
          // onMouseOver={() =>
          //   console.log(
          //     "@todo stop animation by mapping state and condition to className"
          //   )
          // }
        />
        <BottomNavigationAction
          label={<span className="nav-label">GitHub</span>}
          icon={<GitHubIcon className="nav-icon" />}
          onClick={() =>
            window.open("https://github.com/speaud", "_blank", "noreferrer")
          }
        />
      </BottomNavigation>
      <Dialog
        fullWidth={true}
        onClose={handleContactDialogOnClick}
        open={contactDialogIsOpen}
      >
        <DialogTitle>
          {translator(activeLanguageKey, "localeKeyTest")}
        </DialogTitle>
        <Stack
          id="contactFormWrapper"
          direction="column"
          spacing={2}
          alignItems="center"
          justifyContent="center"
        >
          <TextField
            className="contact-form-field"
            required
            label="Full Name"
            variant="outlined"
          />
          <TextField
            className="contact-form-field"
            required
            label="Email"
            variant="outlined"
          />
          <TextField
            className="contact-form-field"
            required
            label="What's up?"
            variant="outlined"
            multiline
            rows={4}
          />
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            justifyContent={"center"}
          >
            <Button onClick={handleContactDialogOnClick}>Cancel</Button>
            <Button>Submit</Button>
          </Stack>
        </Stack>
      </Dialog>
    </>
  );
}

export default App;
